import { useStore } from 'effector-react';
import { Table } from 'entities/Table';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from 'shared/globalConsts';
import { prepareProductsForTable } from 'shared/helpers/prepareProductsForTable';
import { useTranslations } from 'shared/hooks/useTranslations';
import { globalStore } from 'shared/store';
import { productionStore } from 'shared/store';
import { InfoStatusGate, ProductionGate, openProduct } from 'shared/store/productionListStore';
import { filterModelFunc, getColumns, orderColumnVM } from './consts';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import { NoRows } from 'features/NoRows';
import { getLayout } from 'shared/helpers/getLayout';

export const ProductionListGrid = () => {
  // Hooks
  const t = useTranslations();
  // useUpdateDeviceStatus();

  // Store
  const { searchText } = useStore(globalStore);
  const { isDoneShown } = useStore(productionStore);
  const { date, selectedPart } = useStore(globalStore);
  const { isProdListLoading: isLoading, productsData, infoStatus } = useStore(productionStore);

  // State
  const [filterModel, setFilterModel] = useState<GridFilterModel>(filterModelFunc());

  useEffect(() => {
    setFilterModel(filterModelFunc(infoStatus));
  }, [infoStatus]);

  const handleOpenProduct = (e: any) => {
    openProduct(e.row || null);
  };

  const getHeight = () => {
    switch (getLayout()) {
      case 'MOBILE':
        return 'calc(100vh - 225px)';
      default:
        return 'calc(100vh - 178px)';
    }
  };

  return (
    <div style={{ height: getHeight(), margin: '0 20px' }}>
      <ProductionGate date={date} />
      <InfoStatusGate list={productsData?.[date.format(DATE_FORMAT)]?.productsList} />
      <Table
        loading={isLoading}
        onRowClick={handleOpenProduct}
        //
        getRowId={(item: any) => `${item.articleId}_${item.description}`}
        columns={getColumns(t)}
        rows={
          prepareProductsForTable(
            productsData?.[date.format(DATE_FORMAT)]?.productsList,
            searchText,
            selectedPart,
            isDoneShown,
          ) || []
        }
        //
        rowHeight={getLayout() === 'DESKTOP' ? 52 : 120}
        filterModel={filterModel}
        columnVisibilityModel={orderColumnVM}
        slots={{
          noRowsOverlay: NoRows, // no rows at all
          noResultsOverlay: NoRows, // all filtered out
          ...(getLayout() === 'MOBILE' && {
            columnHeaders: () => null,
          }),
        }}
      />
    </div>
  );
};
