import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { DATE_FORMAT } from 'shared/globalConsts';
import { settingDeviceStatus } from 'shared/gql/common.gql';
import { gqlClient } from 'shared/gql/gqlClient';
import { appStore, globalStore, settingsStore } from 'shared/store';

export const useUpdateDeviceStatus = () => {
  const isDisplaysLoading = useStore(settingsStore).isDisplaysLoading;
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const selectedPart = useStore(globalStore).selectedPart;
  const shippingName = useStore(appStore).shippingPoint;

  useEffect(() => {
    if (!isDisplaysLoading && shippingName) {
      gqlClient(settingDeviceStatus, {
        date,
        shippingName,
        part: selectedPart || 1,
      });
    }
  }, [date, isDisplaysLoading, shippingName, selectedPart]);
};
