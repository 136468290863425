const devUserPoolConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: 'eu-west-1:d5f3a9b6-1796-4b6d-af49-4b32e05cd8b7',
  // REQUIRED - Amazon Cognito Region
  region: 'eu-west-1',
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_aSSFte30d',
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: '6rq7egiouhdscfu24u1qtdl4dj',
};
const productionUserPoolConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: 'eu-west-1:46ef19b7-823a-4c10-a0a6-fa23d303688e',
  // REQUIRED - Amazon Cognito Region
  region: 'eu-west-1',
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_ZQAKufUVZ',
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: '1t2j35i84uj8ekvr29np02n9k7',
};
const development = {
  aws: {
    Auth: {
      ...productionUserPoolConfig,
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: window.location.hostname,
        // OPTIONAL - Cookie path
        // path: '/',
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: false,
      },

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    Storage: {
      bucket: 'wawi-dev', //REQUIRED -  Amazon S3 bucket
      region: 'eu-west-1', //OPTIONAL -  Amazon service region
    },

    Analytics: {
      disabled: true,
    },
  },
  api: 'http://localhost:4000/dev',
  // api: 'https://smpdev.hssoft.com/graphql',
  // api: 'https://smp-api.hssoft.com/graphql',
  modules: {
    production_list: {
      page: true,
      components: null,
    },
    distribution_list: {
      page: true,
      components: null,
    },
    shippingPoint: {
      page: true,
      components: null,
    },
    article: {
      page: true,
      components: null,
    },
  },
};
const production = {
  aws: {
    Auth: {
      ...productionUserPoolConfig,
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.hssoft.com',
        // OPTIONAL - Cookie path
        // path: '/',
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    Storage: {
      bucket: 'wawi-prod', //REQUIRED -  Amazon S3 bucket
      region: 'eu-west-1', //OPTIONAL -  Amazon service region
    },

    Analytics: {
      disabled: true,
    },
  },
  api: 'https://smp-api.hssoft.com/graphql',
  modules: {
    production_list: {
      page: true,
      components: null,
    },
    distribution_list: {
      page: true,
      components: null,
    },
    shippingPoint: {
      page: true,
      components: null,
    },
    article: {
      page: false,
      components: null,
    },
  },
};
export const environment = { development, production };
