import { FC, useState, useEffect } from 'react';

import { AppRouter } from './appRouter';
import { PageLoader } from './pageLoader';
import { initApp } from 'shared/hooks/initApp';
import { RenderIf } from 'shared/helpers/renderIf';
import { NewVersionDisclaimer } from 'widgets/NewVersionDisclaimer';

import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material';
import { useStore } from 'effector-react';
import { Toaster } from 'react-hot-toast';
import { appStore } from 'shared/store';
import './index.css';

export const App: FC = () => {
  //
  // Hooks
  const [loading, setLoading] = useState<boolean>(true);
  const [loadInitData, loadSecondaryData] = initApp(setLoading);
  const { themeColor } = useStore(appStore);

  // useEffects
  useEffect(() => {
    startApp();
  }, []); //eslint-disable-line

  // Functions
  const startApp = async () => {
    await loadInitData();
    loadSecondaryData();
  };

  const darkTheme = createTheme({
    palette: {
      mode: themeColor,
    },
  });

  return (
    <>
      <MUIThemeProvider theme={darkTheme}>
        {/* @ts-ignore */}
        <SCThemeProvider
          theme={{
            backGroundColor: themeColor === 'dark' ? '#000' : '#fff',
            textColor: themeColor === 'dark' ? '#fff' : '#000',
          }}
        >
          <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
              duration: 15000,
            }}
          />
          <NewVersionDisclaimer />
          <RenderIf condition={loading}>
            <PageLoader isLoading={loading} />
          </RenderIf>
          <RenderIf condition={!loading}>
            <AppRouter />
          </RenderIf>
        </SCThemeProvider>
      </MUIThemeProvider>
    </>
  );
};
