import { createEffect, createEvent } from 'effector';
import {
  bindDisplays,
  getAllCustomers,
  getAllPrintersData,
  getDeviceList,
  getDevicesInfo,
  getShippingPointsList,
  getTranslations,
  savePrinterSettings,
  unbindDisplays,
  updateCustomersPriorities,
} from 'shared/gql/common.gql';
import { gqlClient } from 'shared/gql/gqlClient';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { updateShippingPoint } from '../appStore';
import { LangType, PrinterDataType, initPrintersList } from './types';
import { getPreparedTranslations } from 'shared/helpers/getPreparedTranslations';
import { detectDefaultLanguage } from 'shared/hooks/initApp/functions/detectDefaultLanguage';
import { errorHandler } from 'shared/helpers/errorHandler';
import {
  BindDeviceMutationVariables,
  SavePrinterSettingsMutationVariables,
  UpdateCustomersPrioritiesMutationVariables,
} from 'graphql/generatedModel';
import { settingsStore } from '..';
import { getGuid } from 'shared/helpers/getGUID';
import { notificationReceived } from '../notificationsStore';

export const initTranslations = createEffect(async () => {
  const data = await gqlClient(getTranslations);
  const translations = getPreparedTranslations(data.smartpicking?.getTranslations || '{}');
  const defaultLanguage = detectDefaultLanguage();
  return { translations, defaultLanguage };
});

export const getShippingPoints = createEffect(async () => {
  const shippingPoints = settingsStore.getState().shippingPoints;
  if (shippingPoints?.length) {
    if (shippingPoints?.length === 1) {
      updateShippingPoint(shippingPoints[0]?.description || null);
    }
    return shippingPoints;
  } else {
    try {
      const res = await gqlClient(getShippingPointsList);
      const shippingPointsList = res.smartpicking?.shippingPointsList;

      if (shippingPointsList?.length === 1) {
        updateShippingPoint(shippingPointsList[0]?.description || null);
      }
      return shippingPointsList;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const updatePriorities = createEffect(
  async (params: UpdateCustomersPrioritiesMutationVariables) => {
    await gqlClient(updateCustomersPriorities, params);
    return params.params;
  },
);

export const getDisplaysData = createEffect(async () => {
  const { displaysData } = settingsStore.getState();
  if (!displaysData?.length) {
    try {
      startDisplaysLoading();
      const allPromise = Promise.all([gqlClient(getDeviceList), gqlClient(getDevicesInfo)]).then(
        ([hanshowData, displaysData]) => [
          hanshowData.smartpicking.getDeviceList,
          displaysData.smartpicking.getDevicesInfo,
        ],
      );
      const [hanshowData, displaysData] = await allPromise;
      const res = hanshowData?.map((el: any) => {
        const dispItem = displaysData.find((d: any) => d?.serialNumber === el?.physicalId);
        if (dispItem) {
          return dispItem;
        } else {
          return { serialNumber: el?.physicalId, sku: null };
        }
      });
      return res;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const getClisentsList = createEffect(async () => {
  const { customersList } = settingsStore.getState();
  if (!customersList?.length) {
    try {
      startCustomersLoading();
      const res = await gqlClient(getAllCustomers);
      const customersList = res.smartpicking?.allCustomers;
      return customersList;
    } catch (e) {
      errorHandler(e);
    }
  }
});

export const updatePrinterSettings = createEffect(
  async (printerData: SavePrinterSettingsMutationVariables) => {
    startPrinterLoading();
    await gqlClient(savePrinterSettings, printerData);
    return printerData;
  },
);

export const getPrinterData = createEffect(async () => {
  const { isPrinterDataInit } = settingsStore.getState();
  if (!isPrinterDataInit) {
    try {
      const res = await gqlClient(getAllPrintersData);
      const printersList = res?.smartpicking?.getAllPrintersByGuid;
      establishWSPrinterConnection(printersList);
      return printersList;
    } catch (e) {
      errorHandler(e);
    }
  }
});

const establishWSPrinterConnection = async (printersList: PrinterDataType[]) => {
  const guid = await getGuid();
  if (guid) {
    const shippingPoint = handleLocalStorage('shippingPointName');
    const currentPrinterData = printersList?.find(
      (printerData) => printerData.shippingPoint === shippingPoint,
    );
    const socket = new WebSocket(
      `wss://j4goni1lh1.execute-api.eu-central-1.amazonaws.com/production/?guid=${guid}&sn=${
        currentPrinterData?.printerId || ''
      }`,
    );
    socket.addEventListener('message', (e) => notificationReceived(JSON.parse(e.data)));
  }
};

export const bindDisplay = createEffect(async (params: BindDeviceMutationVariables) => {
  startDisplaysLoading();
  await gqlClient(bindDisplays, params);
  return params.params as any[];
});

export const unbindDisplay = createEffect(async (removedSN: string) => {
  startDisplaysLoading();
  await gqlClient(unbindDisplays, {
    params: [
      {
        serialNumber: removedSN,
      },
    ],
  });
  return removedSN;
});

export const startPrinterLoading = createEvent();
export const startDisplaysLoading = createEvent();
export const startCustomersLoading = createEvent();
export const setLanguage = createEvent<LangType>();
export const updatePrinterStatus = createEvent<boolean>();

settingsStore
  .on(startPrinterLoading, (state) => {
    return {
      ...state,
      isPrinterDataInit: false,
    };
  })
  .on(updatePrinterStatus, (state, newStatus) => {
    const shippingPoint = handleLocalStorage('shippingPointName');
    return {
      ...state,
      printersList: state?.printersList?.map((printer) => {
        if (printer.shippingPoint === shippingPoint) {
          return { ...printer, isOnline: newStatus };
        } else {
          return printer;
        }
      }),
    };
  })
  .on(updatePriorities, (state, newPriorities) => {
    return {
      ...state,
      displaysData: state.displaysData?.map((el) => {
        const priorities = newPriorities?.params as any[];
        const updatedPriority = priorities?.find((item) => item.sku === el?.sku);
        if (updatedPriority) {
          return { ...el, priority: updatedPriority.priority };
        }
        return el;
      }),
    };
  })
  .on(startDisplaysLoading, (state) => {
    return {
      ...state,
      isDisplaysLoading: true,
    };
  })
  .on(setLanguage, (state, newLang) => {
    handleLocalStorage({ lang: newLang });
    return {
      ...state,
      lang: newLang,
    };
  })
  //
  .on(getShippingPoints.doneData, (state, newShippingPoints) => {
    return {
      ...state,
      shippingPoints: newShippingPoints,
    };
  })
  .on(getPrinterData.doneData, (state, newPrintersList) => {
    if (!newPrintersList) return state;
    return {
      ...state,
      isPrinterDataInit: true,
      printersList: newPrintersList || initPrintersList,
    };
  })
  .on(getDisplaysData.doneData, (state, newDisplaysData: any) => {
    if (!newDisplaysData) return state;
    return {
      ...state,
      isDisplaysLoading: false,
      displaysData: newDisplaysData,
    };
  })
  .on(startCustomersLoading, (state) => {
    return {
      ...state,
      isCustomersLoading: true,
    };
  })
  .on(getClisentsList.doneData, (state, newCustomersList) => {
    if (!newCustomersList)
      return {
        ...state,
        isCustomersLoading: false,
      };
    return {
      ...state,
      isCustomersLoading: false,
      customersList: newCustomersList,
    };
  })
  .on(updatePrinterSettings.doneData, (state, newPrinterData) => {
    return {
      ...state,
      isPrinterDataInit: true,
      printersList: state.printersList?.some(
        (printer) => printer?.shippingPoint === newPrinterData?.params?.shippingPoint,
      )
        ? state.printersList.map((printer) =>
            printer?.shippingPoint === newPrinterData?.params?.shippingPoint
              ? { ...printer, ...newPrinterData?.params }
              : printer,
          )
        : [...state.printersList, { ...newPrinterData?.params }],
    };
  })
  .on(bindDisplay.doneData, (state, bindDisplays) => {
    return {
      ...state,
      isDisplaysLoading: false,
      displaysData: state?.displaysData?.map((display) => {
        console.log('display:', display);
        const modifiedItem = bindDisplays?.find((d1) => d1.serialNumber === display?.serialNumber);
        if (modifiedItem) {
          return {
            ...display,
            ...modifiedItem,
            skuShippingNum: `${modifiedItem?.sku}/${modifiedItem?.shippingNum}`,
          };
        } else {
          return display;
        }
      }),
    };
  })
  .on(unbindDisplay.doneData, (state, removedSN) => {
    return {
      ...state,
      isDisplaysLoading: false,
      displaysData: state?.displaysData?.map((display) => {
        if (display?.serialNumber === removedSN) {
          return {
            serialNumber: removedSN,
            sku: null,
          };
        } else {
          return display;
        }
      }),
    };
  })

  .on(initTranslations.doneData, (state, { translations: translationsToSet, defaultLanguage }) => {
    return {
      ...state,
      translations: translationsToSet,
      lang: defaultLanguage,
      isTranslationsInit: true,
    };
  });
