import { gql } from 'graphql-request';

export const getProductionList = gql`
  query getProductionList($date: String!) {
    smartpicking {
      getProductionList(date: $date) {
        deliveryCycleNum
        articleId
        articleNum
        description
        isIndividualDescription
        sumAmount
        shippingPointId
        shippingPointNum
        shippingPointName
        imageUrl
        productionListOrders {
          amount
          orderId
          deliveryNoteNum
          customerId
          customerNum
          internalName
          distributionLowerLimitInPercent
          distributionHigherLimitInPercent
          productStatus
          responsibleId
          distributed
          toDistribute
          priority
        }
      }
    }
  }
`;
export const getDistributionList = gql`
  query getDistributionList($date: String!) {
    smartpicking {
      getDistributionList(date: $date) {
        deliveryCycleNum
        customerId
        customerNum
        internalName
        deliveryTime
        deliveryAddress
        rotePlan
        orderId
        deliveryNoteNum
        products {
          articleId
          articleNum
          description
          isIndividualDescription
          sumAmount
          numberPerBakingSheet
          shippingPointId
          shippingPointNum
          shippingPointName
          toDistribute
          distributed
          productStatus
          imageUrl
        }
      }
    }
  }
`;

export const getAllProducts = gql`
  query getArticles {
    smartpicking {
      getArticles {
        id
        articleNo
        description
      }
    }
  }
`;

export const updateProduct = gql`
  mutation updateProduct(
    $date: String!
    $productId: Int!
    $productName: String!
    $shippingName: String!
    $fields: [ProductUpdateFields]!
  ) {
    smartpicking {
      updateProduct(
        date: $date
        productId: $productId
        productName: $productName
        shippingName: $shippingName
        fields: $fields
      )
    }
  }
`;

export const deleteProduct = gql`
  mutation deleteProductItems($params: [DeleteProductsInput!]!) {
    smartpicking {
      deleteProductItems(params: $params)
    }
  }
`;

export const updateDevice = gql`
  query updateDevice($fields: DeviceItemFieldsInput!) {
    smartpicking {
      updateDevice(fields: $fields)
    }
  }
`;

export const getDevicesInfo = gql`
  query getDevicesInfo {
    smartpicking {
      getDevicesInfo {
        sku
        priority
        serialNumber
        shippingPoint
        skuShippingNum
      }
    }
  }
`;

export const getAllCustomers = gql`
  query allCustomers {
    smartpicking {
      allCustomers {
        internalOrFullName
        customerId
        customerNo
      }
    }
  }
`;

export const getDeviceList = gql`
  query getDeviceList {
    smartpicking {
      getDeviceList {
        physicalId
      }
    }
  }
`;

export const getAllPrintersData = gql`
  query getAllPrintersByGuid {
    smartpicking {
      getAllPrintersByGuid {
        printerId
        localIP
        isLocalConnection
        shippingPoint
        isOnline
      }
    }
  }
`;

export const getPrinterSettings = gql`
  query getPrinterByShippingPoint($shippingPoint: String!) {
    smartpicking {
      getPrinterByShippingPoint(shippingPoint: $shippingPoint) {
        printerId
        localIP
        isLocalConnection
        shippingPoint
        isOnline
      }
    }
  }
`;

export const savePrinterSettings = gql`
  mutation savePrinterSettings($params: PrinterSettingsInput!) {
    smartpicking {
      savePrinterSettings(params: $params)
    }
  }
`;

export const bindDisplays = gql`
  mutation bindDevice($params: [BindDeviceInput]) {
    smartpicking {
      bindDevice(params: $params)
    }
  }
`;

export const unbindDisplays = gql`
  mutation unbindDevice($params: [UnbindDeviceInput!]!) {
    smartpicking {
      unbindDevice(params: $params)
    }
  }
`;

export const clearDisplay = gql`
  mutation clearDisplaysData($customersSku: [String!]!) {
    smartpicking {
      clearDisplaysData(customersSku: $customersSku)
    }
  }
`;

export const updateCustomersPriorities = gql`
  mutation updateCustomersPriorities($params: [CustomersPrioritiesInput!]!) {
    smartpicking {
      updateCustomersPriorities(params: $params)
    }
  }
`;

export const editOrders = gql`
  mutation editOrders($orders: [OrderInfo!]!) {
    smartpicking {
      editOrders(orders: $orders)
    }
  }
`;

export const settingDeviceStatus = gql`
  mutation settingDeviceStatus($date: String!, $shippingName: String!, $part: Int!) {
    smartpicking {
      settingDeviceStatus(date: $date, shippingName: $shippingName, part: $part)
    }
  }
`;

export const createOrder = gql`
  mutation createOrder($params: CreateOrderInput!) {
    smartpicking {
      createOrder(params: $params)
    }
  }
`;

export const printOutForCustomers = gql`
  mutation printOutForCustomers($printerId: String!, $printData: PrintData!) {
    smartpicking {
      printOutForCustomers(printerId: $printerId, printData: $printData) {
        isPrinterOnline
      }
    }
  }
`;

export const getShippingPointsList = gql`
  query shippingPointsList {
    smartpicking {
      shippingPointsList {
        shippingId
        shippingNum
        description
      }
    }
  }
`;

export const getTranslations = gql`
  query getTranslations {
    smartpicking {
      getTranslations
    }
  }
`;

export const getNotifications = gql`
  query getNotifications {
    smartpicking {
      getNotifications {
        notificationId
        message
        isRead
        timestamp
      }
    }
  }
`;

export const markNotificationAsRead = gql`
  mutation markNotificationAsRead($notificationIds: [String!]!) {
    smartpicking {
      markNotificationAsRead(notificationIds: $notificationIds)
    }
  }
`;
