import { useStore } from 'effector-react';
import { AvailableStatus, PageNamesEnum } from 'graphql/generatedModel';
import { FC, useCallback, useEffect, useState } from 'react';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { DATE_FORMAT } from 'shared/globalConsts';
import { prepareProductsForTable } from 'shared/helpers/prepareProductsForTable';
import {
  SmartCalcErrors,
  distributeWithoutCalculation,
  smartCalculationWithSorting,
} from 'shared/helpers/smartCalculation/helpers';
import { appStore, globalStore, settingsStore } from 'shared/store';
import { productionStore } from 'shared/store';
import { updateOrders } from 'shared/store';
import { closeProduct } from 'shared/store/productionListStore';
import { startModalStore } from 'shared/store';
import { setProductsToUpdDistributed, updateFixedNumber } from 'shared/store/startModalStore';
import { ProductToUpdate } from 'shared/types';

import { RenderIf } from 'shared/helpers/renderIf';
import { updateDisplay } from 'shared/api';
import { getDistributorId } from 'shared/helpers/getDistributorId';
import { getProductStatus } from 'shared/helpers/getProductStatus';
import { useCloseModalWithDelay } from 'shared/helpers/useCloseModalWithDelay';
import { getLayout } from 'shared/helpers/getLayout';
import { MobileOrderModal } from './MobileOrderModal';
import { DesktopOrderModal } from './DesktopOrderModal';
import { ManualDistributing } from './ManualDistributingModal';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import { useGetSkuShippingPointNum } from 'shared/hooks/useGetSkuShippingPointNum';

// TODO: 99 refactor this component

export const OrderModalComponent: FC<IOrderModal> = ({ startClosing }) => {
  //
  // Hooks
  const { shippingPoint: shippingPointName } = useStore(appStore);
  const getSkuShippingNum = useGetSkuShippingPointNum();

  const [manualDistributingProgress, startClosingManualDistributing] = useCloseModalWithDelay(
    closeProduct,
    3500,
  ); // eslint-disable-line

  // State

  const [isNextModalOpen, setIsNextModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [justProduced, setJustProduced] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<number | string>('');

  const [productsList, setProductsList] = useState<any[]>();
  const [productInProgressId, setProductInProgressId] = useState<number | null>(null);

  const [isManualDistributionEnabled, setIsManualDistributionEnabled] = useState<boolean>(false);
  const [error, setError] = useState<SmartCalcErrors | null>(null);

  const [ordersToDisplay, setOrdersToDisplay] = useState<any[]>([]);
  const [productsToUpdate, setProductsToUpdate] = useState<ProductToUpdate[]>([]);

  //
  const date = useStore(globalStore).date.format(DATE_FORMAT);
  const { selectedPart } = useStore(globalStore);
  const { currentOpenProduct: product, productsData } = useStore(productionStore) as any;
  const { fixedNumber, calcNumber, amountType } = useStore(startModalStore);

  useEffect(() => {
    if (amountType === 'fixed') setJustProduced(fixedNumber);
    if (amountType === 'calculate') setJustProduced(calcNumber);
  }, [amountType, fixedNumber, calcNumber]);

  useEffect(() => {
    setProductsList(
      prepareProductsForTable(productsData?.[date]?.productsList, '', selectedPart, true),
    );
  }, [productsData, selectedPart]); // eslint-disable-line

  useEffect(() => {
    const respId = product?.productionListOrders?.[0]?.responsibleId;
    if (respId) {
      setSelectedUser(respId);
    }
  }, [product]);

  useEffect(() => {
    if (getLayout() === 'MOBILE') {
      const selectedDistributorId = handleLocalStorage('selectedDistributorId');
      setSelectedUser(selectedDistributorId);
    }
  }, []);

  //

  useEffect(() => {
    if (isManualDistributionEnabled) {
      const distributed = distributeWithoutCalculation(
        product?.productionListOrders as never[],
        justProduced,
      );
      const newFixed = distributed?.reduce((acc: any, curr: any) => {
        return acc + curr?.justProduced;
      }, 0);
      setOrdersToDisplay(distributed);
      updateFixedNumber(Number(newFixed || 0));
      setError(null);
    }
  }, [product, isManualDistributionEnabled]); // eslint-disable-line

  useEffect(() => {
    if (!isManualDistributionEnabled) {
      updateCustomerToDisplay();
    }
  }, [justProduced, product, isManualDistributionEnabled]); // eslint-disable-line

  useEffect(() => {
    checkIfUserIsBusy();
  }, [selectedUser]); // eslint-disable-line
  // }, [selectedUser, productInProgressId]); // eslint-disable-line

  useEffect(() => {
    setProductsToUpdDistributed(productsToUpdate);
  }, [productsToUpdate]);

  // Functions
  const updateCustomerToDisplay = () => {
    const [ordersToSet, error] = smartCalculationWithSorting(
      product?.productionListOrders as never[],
      Number(justProduced || 0),
    );
    setOrdersToDisplay(ordersToSet);
    setError(error);
  };

  const handleSelectedUserMobile = (newUser: number | string) => {
    handleLocalStorage({ selectedDistributorId: newUser });
    setSelectedUser(newUser);
  };

  const checkIfUserIsBusy = () => {
    setProductInProgressId(
      (() => {
        const listToCheck: any = productsList || [];
        for (const item of listToCheck) {
          if (Array.isArray(item.productionListOrders)) {
            for (const order of item.productionListOrders) {
              if (
                // getProductStatus(order) === 'In progress' &&
                order?.productStatus === AvailableStatus.IN_PROGRESS &&
                order?.responsibleId === selectedUser
              ) {
                return item.articleId;
              }
            }
          }
        }
        return null;
      })(),
    );
  };

  const handleStartClick = async () => {
    if (!getIsStartDisabled()) {
      startClosing();
      setIsLoading(true);

      const ordersToUpdate: any[] = ordersToDisplay?.reduce((acc, order) => {
        // TODO: check productStatus
        const orderISNotDone =
          order?.productStatus !== 'DONE' && order?.productStatus !== 'Done' && order?.justProduced;
        const isProductDone = getProductStatus(product) === 'Done' && order?.justProduced;
        if (orderISNotDone || isProductDone) {
          // TODO: refactor this hell
          const isOrderInAcc = acc.some((item: any) => item.customerId === order.customerId);
          if (isOrderInAcc) {
            return acc?.map((el: any) => {
              if (el.customerId === order.customerId) {
                return {
                  ...el,
                  justProduced: Number(el.justProduced) + Number(order.justProduced),
                };
              } else {
                return el;
              }
            });
          } else {
            return [...acc, order];
          }
        }
        return acc;
      }, []);

      await updateOrders({
        date,
        productId: Number(product?.articleId),
        productName: product?.description,
        // TODO: 99 remove pageName when BE is ready
        // pageName: PageNamesEnum.PRODUCTION,
        shippingName: shippingPointName || '',
        fields: ordersToDisplay
          ?.filter((order) => {
            return order?.productStatus !== AvailableStatus.DONE && order?.justProduced;
          })
          ?.map((order) => ({
            orderId: Number(order?.orderId),
            partNum: product?.deliveryCycleNum,
            productStatus: AvailableStatus.IN_PROGRESS,
            responsibleId: +selectedUser,
            toDistribute: order.justProduced,
          })),
      });

      updateDisplay({
        date,
        productName: product?.description,
        productStatus: AvailableStatus.IN_PROGRESS,
        shippingName: shippingPointName || '',
        customers: ordersToUpdate?.map((order) => ({
          sku: getSkuShippingNum(order.customerNum),
          customerId: Number(order.customerId),
          amount: order.justProduced,
          distributorId: getDistributorId(selectedUser as number),
        })),
      });
    }
  };

  // Checkers
  const getIsStartDisabled = () => {
    return (
      !selectedUser ||
      Boolean(!justProduced) ||
      error === SmartCalcErrors.ALL ||
      error === SmartCalcErrors.LIMIT ||
      error === SmartCalcErrors.COMMON ||
      Boolean(productInProgressId)
    );
  };

  const handleRowEditCommit = useCallback(
    (newValue, oldValue) => {
      if (newValue) {
        if (newValue?.justProduced !== oldValue?.justProduced) {
          updateFixedNumber(Number(justProduced + newValue?.justProduced - oldValue?.justProduced));
          setOrdersToDisplay((prevValue) => {
            return [
              ...prevValue.map((currOrder) => {
                if (
                  currOrder?.deliveryNoteNum === newValue?.deliveryNoteNum &&
                  currOrder?.internalName === newValue?.internalName &&
                  currOrder?.customerId === newValue?.customerId &&
                  currOrder?.orderId === newValue?.orderId
                ) {
                  return newValue;
                }
                return currOrder;
              }),
            ];
          });
          return { ...newValue, isNew: false };
        }
        if (newValue?.distributed !== oldValue?.distributed) {
          const id = `${newValue.customerNum}_${newValue.deliveryNoteNum}`;
          const value = newValue.distributed;
          setProductsToUpdate((prevValue) => {
            const neededProduct = ordersToDisplay?.find(
              (p) => `${p.customerNum}_${p.deliveryNoteNum}` === id,
            );
            if (neededProduct) {
              const isValueSame = neededProduct.amount === value;
              const isRecordExisting = prevValue?.some((order) => order.id === id);

              if (isValueSame) {
                return [...prevValue.filter((el) => el.id !== id)];
              }
              if (!isValueSame && isRecordExisting) {
                return prevValue?.map((el) => {
                  if (el.id === id) {
                    return { ...el, producedAmount: value };
                  }
                  return el;
                });
              }
              if (!isValueSame && !isRecordExisting) {
                return [
                  ...prevValue,
                  {
                    id: id,
                    producedAmount: value,
                    orderId: neededProduct.orderId,
                    description: product?.description,
                    partNum: product?.deliveryCycleNum,
                  },
                ];
              }
              return prevValue;
            } else {
              return prevValue;
            }
          });
          return { ...newValue, isNew: false };
        }
      }
    },
    [product, productsToUpdate, ordersToDisplay, justProduced], // eslint-disable-line
  );

  const isStatusToDo = (product: any) => {
    return (
      product?.productionListOrders?.[0]?.productStatus === 'TO_DO' ||
      product?.productionListOrders?.[0]?.productStatus === 'To do' ||
      product?.productionListOrders?.[0]?.productStatus === null
    );
  };

  const getTotalProduced = () => {
    return product?.productionListOrders?.reduce((acc: number, curr: any) => {
      return acc + curr.distributed;
    }, 0);
  };

  const getInitAmountValue = () => {
    const res = Number(product?.sumAmount) - Number(getTotalProduced());
    return res > 0 ? res : 0;
  };

  return (
    <>
      <RenderIf condition={getLayout() === 'DESKTOP'}>
        <DesktopOrderModal
          // TODO: move props that are not used in MobileOrderModal inside DesktopOrderModal
          product={product}
          isLoading={isLoading}
          error={error}
          //
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          //
          isManualDistributionEnabled={isManualDistributionEnabled}
          setIsManualDistributionEnabled={setIsManualDistributionEnabled}
          //
          productInProgressId={productInProgressId}
          setProductInProgressId={setProductInProgressId}
          //
          setIsNextModalOpen={setIsNextModalOpen}
          startClosing={startClosing}
          ordersToDisplay={ordersToDisplay}
          handleStartClick={handleStartClick}
          productsList={productsList}
          productsToUpdate={productsToUpdate}
          isStatusToDo={isStatusToDo}
          getInitAmountValue={getInitAmountValue}
          getIsStartDisabled={getIsStartDisabled}
          handleRowEditCommit={handleRowEditCommit}
        />
      </RenderIf>
      <RenderIf condition={getLayout() === 'MOBILE'}>
        <MobileOrderModal
          product={product}
          isLoading={isLoading}
          error={error}
          //
          selectedUser={selectedUser}
          setSelectedUser={handleSelectedUserMobile}
          //
          productInProgressId={productInProgressId}
          setProductInProgressId={setProductInProgressId}
          //
          isManualDistributionEnabled={isManualDistributionEnabled}
          //
          startClosing={startClosing}
          ordersToDisplay={ordersToDisplay}
          productsToUpdate={productsToUpdate}
          productsList={productsList}
          isStatusToDo={isStatusToDo}
          getIsStartDisabled={getIsStartDisabled}
          handleStartClick={handleStartClick}
          setIsNextModalOpen={setIsNextModalOpen}
          getInitAmountValue={getInitAmountValue}
        />
      </RenderIf>

      <ManualDistributing
        headerTitle={product?.description || ''}
        isOpen={isNextModalOpen}
        progress={manualDistributingProgress}
        onClose={() => setIsNextModalOpen(false)}
        startClosing={startClosingManualDistributing}
        totalProduced={justProduced}
        initialOrders={ordersToDisplay}
        selectedUser={selectedUser}
      />
    </>
  );
};

interface IOrderModal {
  startClosing: () => void;
}

export const OrderModal = withModalHOC(OrderModalComponent);
