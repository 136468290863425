import { Store, createEffect, createEvent, createStore } from 'effector';
import { AppStoreType, initAppStore } from './appStore/types';
import { GlobalStoreType, initGlobalStore } from './globalStore/types';
import { SettingsStoreType, initSettingsStore } from './settingsStore/types';
import { StartModalStoreType, initStartModalStore } from './startModalStore/types';
import { ProductionListStoreType, initProductionListStore } from './productionListStore/types';
import {
  DistributionListStoreType,
  initDistributionListStore,
} from './distributionListStore/types';
import {
  AvailableStatus,
  DeleteProductItemsMutationVariables,
  UpdateProductMutationVariables,
} from 'graphql/generatedModel';
import { gqlClient } from 'shared/gql/gqlClient';
import { deleteProduct, updateProduct } from 'shared/gql/common.gql';
import { errorHandler } from 'shared/helpers/errorHandler';
import { editOrders } from 'shared/api';
import { getOrdersToEditForProdList } from 'shared/helpers/getOrdersToEdit/forProdList';
import { getOrdersToEditForDistList } from 'shared/helpers/getOrdersToEdit/forDistList';
import { NotificationsStoreType, initNotificationsStore } from './notificationsStore/types';
import { handleLocalStorage } from 'shared/helpers/handleLocalStorage';
import toast from 'react-hot-toast';
import moment from 'moment';

export const updateOrders = createEffect(async (product: UpdateProductMutationVariables) => {
  try {
    startLoading();
    // throw new Error('Fake error for test.');
    await gqlClient(updateProduct, product);
    return product;
  } catch (e: any) {
    toast.error(moment().format('YYYY.MM.DD'));
    toast.error(moment().format('HH:mm:ss'));
    toast.error(e.message);
    errorHandler(e);
    stopLoading();
  }
});

export const discardProduct = createEffect(async (params: DeleteProductItemsMutationVariables) => {
  try {
    startLoading();
    await gqlClient(deleteProduct, params);
    const paramsInner = params.params as any[];
    return { date: paramsInner?.[0]?.dateFor, deletedProducts: params.params };
  } catch (e: any) {
    toast.error(moment().format('YYYY.MM.DD'));
    toast.error(moment().format('HH:mm'));
    toast.error(e.message);
    errorHandler(e);
    stopLoading();
  }
});

export const startLoading = createEvent();
export const stopLoading = createEvent();

export const appStore: Store<AppStoreType> = createStore<AppStoreType>(initAppStore);

export const globalStore: Store<GlobalStoreType> = createStore<GlobalStoreType>(initGlobalStore);

export const settingsStore: Store<SettingsStoreType> =
  createStore<SettingsStoreType>(initSettingsStore);

export const startModalStore: Store<StartModalStoreType> =
  createStore<StartModalStoreType>(initStartModalStore);

export const notificationsStore: Store<NotificationsStoreType> =
  createStore<NotificationsStoreType>(initNotificationsStore);

export const productionStore: Store<ProductionListStoreType> =
  createStore<ProductionListStoreType>(initProductionListStore);

export const distributionStore: Store<DistributionListStoreType> =
  createStore<DistributionListStoreType>(initDistributionListStore);

productionStore
  .on(startLoading, (state) => {
    return {
      ...state,
      isProdListLoading: true,
    };
  })
  .on(stopLoading, (state) => {
    return {
      ...state,
      isProdListLoading: false,
    };
  })
  .on(updateOrders.doneData, (state, updProduct) => {
    if (updProduct && state.productsData?.[updProduct?.date]?.lastUpdated) {
      const newProductsData = {
        ...state.productsData,
        [updProduct?.date]: {
          lastUpdated: state.productsData[updProduct?.date].lastUpdated,
          productsList: state.productsData[updProduct?.date].productsList?.map((product) => {
            const isUpd =
              Number(updProduct.productId) === Number(product?.articleId) &&
              updProduct.productName === product?.description;
            if (isUpd) {
              const productionListOrders =
                product?.productionListOrders?.map((order) => {
                  const fields = updProduct.fields as [];
                  const updatedData =
                    fields?.find(
                      (field: any) => Number(field.orderId) === Number(order?.orderId),
                    ) || {};
                  return {
                    ...order,
                    ...updatedData,
                    amount: getNewAmount(order, updatedData),
                  };
                }) || [];
              return {
                ...product,
                sumAmount: getNewTotal(productionListOrders),
                productionListOrders,
              };
            } else {
              return product;
            }
          }),
        },
      };
      if (window.location.pathname === '/production_list') {
        const ordersToEdit = getOrdersToEditForProdList(newProductsData, [updProduct]);

        if (ordersToEdit?.length) {
          editOrders({
            orders: ordersToEdit,
          });
        }
      }
      return {
        ...state,
        isProdListLoading: false,
        productsData: newProductsData,
      };
    } else {
      return state;
    }
  })
  .on(discardProduct.doneData, (state, { date, deletedProducts }: any) => {
    if (deletedProducts) {
      return {
        ...state,
        isProdListLoading: false,
        productsData: {
          ...state.productsData,
          [date]: {
            lastUpdated: state.productsData[date].lastUpdated,
            productsList: state.productsData[date].productsList?.map((productFD) => {
              const deletedItem = deletedProducts?.some(
                (deletedItem: any) =>
                  Number(deletedItem?.productId) === Number(productFD?.articleId) &&
                  deletedItem.productName === productFD?.description,
              );
              if (deletedItem) {
                return {
                  ...productFD,
                  productionListOrders: productFD?.productionListOrders?.map((order) => {
                    const deletedOrder = deletedProducts?.some(
                      (deletedItem: any) =>
                        Number(deletedItem?.productId) === Number(productFD?.articleId) &&
                        deletedItem?.productName === productFD?.description &&
                        Number(deletedItem?.orderId) === Number(order?.orderId),
                    );
                    if (deletedOrder) {
                      return {
                        ...order,
                        distributed: null,
                        toDistribute: null,
                        productStatus: null,
                        responsibleId: null,
                      };
                    }
                    return order;
                  }),
                };
              }
              return productFD;
            }),
          },
        },
      };
    }
    return state;
  });

distributionStore
  .on(startLoading, (state) => {
    return {
      ...state,
      isDistrListLoading: true,
    };
  })
  .on(updateOrders.doneData, (state, updProduct) => {
    if (updProduct && state.lists?.[updProduct?.date]?.lastUpdated) {
      const newOrdersList =
        state.lists[updProduct?.date]?.ordersList?.map((order) => ({
          ...order,
          products: order?.products?.map((product) => {
            const fields = updProduct.fields as [];
            const foundProduct = fields?.find((field: any) => {
              return (
                Number(field.orderId) === Number(order?.orderId) &&
                Number(updProduct.productId) === Number(product?.articleId) &&
                updProduct.productName === product?.description
              );
            });
            const isOrderFinished = checkIsOrderFinished(
              order?.products as any[],
              `${updProduct?.productId}_${updProduct?.productName}`,
            );
            if (foundProduct) {
              return {
                ...product,
                // @ts-ignore
                distributed: foundProduct?.distributed,
                // @ts-ignore
                toDistribute: foundProduct?.toDistribute,
                // @ts-ignore
                productStatus: foundProduct?.productStatus,
                sumAmount: isOrderFinished
                  ? // @ts-ignore
                    foundProduct?.distributed
                  : product?.sumAmount,
              };
            } else if (isOrderFinished) {
              return {
                ...product,
                sumAmount: product?.distributed || product?.sumAmount,
              };
            } else {
              return product;
            }
          }),
        })) || [];

      const ordersToEdit = getOrdersToEditForDistList(newOrdersList, [updProduct]);

      const lists = {
        ...state.lists,
        [updProduct?.date]: {
          lastUpdated: state.lists?.[updProduct?.date]?.lastUpdated,
          ordersList: newOrdersList,
        },
      };
      if (window.location.pathname === '/distribution_list') {
        if (ordersToEdit?.length) {
          editOrders({
            orders: ordersToEdit as any[],
          });
        }
      }
      return {
        ...state,
        isDistrListLoading: false,
        lists,
      };
    } else {
      return state;
    }
  })
  .on(discardProduct.doneData, (state, { date, deletedProducts }: any) => {
    ////
    if (deletedProducts && date) {
      const newOrdersList =
        state.lists[date]?.ordersList?.map((order) => ({
          ...order,
          products: order?.products?.map((product) => {
            const deletedItem = deletedProducts?.some(
              (deletedItem: any) =>
                Number(deletedItem?.productId) === Number(product?.articleId) &&
                deletedItem.productName === product?.description,
            );
            if (deletedItem) {
              console.log('product:', product);
              return {
                ...product,
                distributed: null,
                toDistribute: null,
                productStatus: null,
              };
            } else {
              return product;
            }
          }),
        })) || [];
      return {
        ...state,
        isDistrListLoading: false,
        lists: {
          ...state.lists,
          [date]: {
            lastUpdated: state.lists?.[date]?.lastUpdated,
            ordersList: newOrdersList,
          },
        },
      };
    } else {
      return state;
    }
  });

const checkIsOrderFinished = (products: any[], productSample?: string) => {
  const shippingPointName = handleLocalStorage('shippingPointName');
  const productsFiltered = products?.filter(
    (product) =>
      product.shippingPointName === shippingPointName &&
      `${product?.articleId}_${product?.description}` !== productSample,
  );
  return (
    productsFiltered?.length &&
    productsFiltered?.every((el) => el.productStatus === AvailableStatus.DONE)
  );
};

const getNewTotal = (orders: any[]) => {
  return orders?.reduce((acc, curr) => {
    if (curr.productStatus === AvailableStatus.DONE) {
      return acc + curr.distributed;
    }
    return acc + curr.amount;
  }, 0);
};

const getNewAmount = (order: any, updatedData: any) => {
  if (updatedData.productStatus === AvailableStatus.DONE) {
    return isNaN(updatedData.distributed) ? order.amount : updatedData.distributed;
  } else {
    return order.amount;
  }
};
