import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ProductImage } from '../styles';
import productPlaceholder from 'shared/images/productPlaceholder.svg';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { columns } from '../consts';
import { withModalHOC } from 'shared/HOCs/withModalHOC';
import { Button } from 'entities/Button';
import { useStore } from 'effector-react';
import { appStore, globalStore } from 'shared/store';
import { productionStore } from 'shared/store';
import { updateOrders } from 'shared/store';
import { RenderIf } from 'shared/helpers/renderIf';
import { useTranslations } from 'shared/hooks/useTranslations';
import { AvailableStatus, PageNamesEnum } from 'graphql/generatedModel';
import { Table } from 'entities/Table';
import { ButtonsContainer } from '../styles';
import {
  DistributingContainer,
  HeaderText,
  MainContainer,
  SubheaderText,
  TableContainer,
} from './styles';
import { aggregationModel } from './consts';
import { updateDisplay } from 'shared/api';
import { getDistributorId } from 'shared/helpers/getDistributorId';
import { DATE_FORMAT } from 'shared/globalConsts';
import { getLayout } from 'shared/helpers/getLayout';
import { useGetSkuShippingPointNum } from 'shared/hooks/useGetSkuShippingPointNum';

export const ManualDistributingComponent: FC<IManualDistributing> = ({
  initialOrders,
  totalProduced,
  selectedUser,
  onClose,
  startClosing,
}) => {
  const t = useTranslations();
  const apiRef = useGridApiRef();
  const { shippingPoint: shippingPointName } = useStore(appStore);
  const getSkuShippingNum = useGetSkuShippingPointNum();

  const { currentOpenProduct: product } = useStore(productionStore);
  const date = useStore(globalStore).date.format(DATE_FORMAT);

  const [ordersToDisplay, setOrdersToDisplay] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setOrdersToDisplay(initialOrders);
  }, [initialOrders]);

  const yetToDistribute = useMemo(() => {
    return (
      totalProduced -
      ordersToDisplay?.reduce((acc, curr) => {
        return acc + curr.justProduced || 0;
      }, 0)
    );
  }, [ordersToDisplay]); // eslint-disable-line

  const handleRowEditCommit = useCallback(
    (newValue, oldValue) => {
      if (newValue) {
        if (newValue?.justProduced !== oldValue?.justProduced) {
          setOrdersToDisplay((prevValue) => {
            return [
              ...prevValue.map((currOrder) => {
                if (
                  currOrder?.deliveryNoteNum === newValue?.deliveryNoteNum &&
                  currOrder?.internalName === newValue?.internalName &&
                  currOrder?.customerId === newValue?.customerId &&
                  currOrder?.orderId === newValue?.orderId
                ) {
                  return newValue;
                }
                return currOrder;
              }),
            ];
          });
          return { ...newValue, isNew: false };
        }
      }
    },
    [ordersToDisplay], // eslint-disable-line
  );

  const handleStartClick = async () => {
    if (!yetToDistribute) {
      startClosing();
      setIsLoading(true);

      const ordersToUpdate: any[] = ordersToDisplay?.reduce((acc, order) => {
        const orderISNotDone =
          order?.productStatus !== 'DONE' && order?.productStatus !== 'Done' && order?.justProduced;
        if (orderISNotDone) {
          const isOrderInAcc = acc.some((item: any) => item.customerId === order.customerId);
          if (isOrderInAcc) {
            return acc?.map((el: any) => ({
              ...el,
              justProduced: Number(el.justProduced) + Number(order.justProduced),
            }));
          } else {
            return [...acc, order];
          }
        }
        return acc;
      }, []);

      await updateOrders({
        date,
        productId: Number(product?.articleId),
        productName: product?.description || '',
        // TODO: 99 remove pageName when BE is ready
        // pageName: PageNamesEnum.PRODUCTION,
        shippingName: shippingPointName || '',
        fields: ordersToUpdate?.map((order) => ({
          orderId: Number(order?.orderId),
          partNum: product?.deliveryCycleNum,
          productStatus: AvailableStatus.IN_PROGRESS,
          responsibleId: +selectedUser,
          toDistribute: order.justProduced,
        })),
      });

      updateDisplay({
        date,
        productName: product?.description || '',
        productStatus: AvailableStatus.IN_PROGRESS,
        shippingName: shippingPointName || '',
        customers: ordersToUpdate?.map((order) => ({
          sku: getSkuShippingNum(order.customerNum),
          customerId: Number(order.customerId),
          amount: order.justProduced,
          distributorId: getDistributorId(selectedUser as number),
        })),
      });
    }
  };

  return (
    <MainContainer>
      {/* TODO: 7 reenable loading */}
      {/* <RenderIf condition={Boolean(progress)}>
        <LinearProgress variant="determinate" value={progress} />
      </RenderIf>
      <RenderIf condition={Boolean(!progress)}>
        <Box height="4px" />
      </RenderIf> */}
      <RenderIf condition={Boolean(yetToDistribute)}>
        <HeaderText>
          <span>{yetToDistribute} </span>
          {t('production.itemsToBeDistributed')}
        </HeaderText>
        <SubheaderText>{t('production.allocateManually')}</SubheaderText>
      </RenderIf>
      <RenderIf condition={Boolean(!yetToDistribute)}>
        <HeaderText>{t('production.allItemsAreDistributed')}</HeaderText>
        <SubheaderText>{t('production.clickStartToContinue')}</SubheaderText>
      </RenderIf>
      <DistributingContainer>
        <RenderIf condition={getLayout() === 'DESKTOP'}>
          <ProductImage src={product?.imageUrl?.length ? product?.imageUrl : productPlaceholder} />
        </RenderIf>
        <TableContainer>
          <Table
            apiRef={apiRef}
            editMode="row"
            getRowId={(item: any) => `${item.customerId}_${item.orderId}`}
            rows={ordersToDisplay || []}
            columns={columns(t, [])}
            processRowUpdate={handleRowEditCommit}
            onProcessRowUpdateError={(error: any) => console.log(error?.message)}
            aggregationModel={aggregationModel}
            isCellEditable={(e: any) => e?.field === 'justProduced'}
          />
        </TableContainer>
      </DistributingContainer>
      <ButtonsContainer>
        <Button
          handleClick={onClose}
          variant="outlined"
          isDisabled={isLoading}
          isLoading={isLoading}
          sx={{
            margin: 0,
            height: '42px',
            width: '200px',
          }}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          isDisabled={Boolean(yetToDistribute || isLoading)}
          handleClick={handleStartClick}
          variant="contained"
          isLoading={isLoading}
          sx={{
            margin: 0,
            height: '42px',
            width: '200px',
          }}
        >
          {t('buttons.start')}
        </Button>
      </ButtonsContainer>
    </MainContainer>
  );
};

interface IManualDistributing {
  initialOrders: any[];
  totalProduced: number;
  onClose: () => void;
  startClosing: () => void;
  selectedUser: number | string;
}

export const ManualDistributing = withModalHOC(ManualDistributingComponent);
