export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Color: any;
  Currency: any;
  Date: any;
  DateTime: any;
  IBAN: any;
  JSON: any;
  JSONObject: any;
  PhoneNumber: any;
  PinCode: any;
  PositiveInt: any;
  Time: any;
  Timestamp: any;
};

export enum AvailableDistributorId {
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
}

export enum AvailableStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  TO_DO = 'TO_DO',
}

export type BindDeviceInput = {
  customerName: Scalars['String'];
  serialNumber: Scalars['String'];
  shippingPoint?: InputMaybe<Scalars['String']>;
  sku: Scalars['String'];
};

export enum C_Attached_Orders_Action {
  AOA0_UNBIND = 'AOA0_UNBIND',
  AOA1_DELETE = 'AOA1_DELETE',
}

export enum C_Availability_In_Procurement_System {
  AIPS0_NOT_AVAILABLE = 'AIPS0_NOT_AVAILABLE',
  AIPS1_MERCHANDISE_ARTICLE = 'AIPS1_MERCHANDISE_ARTICLE',
  AIPS2_OWN_PRODUCED_ARTICLE = 'AIPS2_OWN_PRODUCED_ARTICLE',
  AIPS3_RAW_MATERIAL_FOR_BRANCH = 'AIPS3_RAW_MATERIAL_FOR_BRANCH',
}

export enum C_Besr_Format {
  BF1_HSSOFT = 'BF1_HSSOFT',
  BF2_ABACUS = 'BF2_ABACUS',
  BF3_ABACUS2 = 'BF3_ABACUS2',
}

export enum C_Booking_Type {
  BO1_BOOKING_DATE_CREDIT = 'BO1_BOOKING_DATE_CREDIT',
  BO2_BOOKING_PROCESSING_DATE = 'BO2_BOOKING_PROCESSING_DATE',
}

export enum C_Ca_Visibility_Status {
  CAVS1_ALWAYS = 'CAVS1_ALWAYS',
  CAVS2_ONLY_CR_MODE = 'CAVS2_ONLY_CR_MODE',
  CAVS3_ONLY_ORDER_MODE = 'CAVS3_ONLY_ORDER_MODE',
  CAVS4_DISABLED = 'CAVS4_DISABLED',
}

export enum C_Country {
  CO1_SWITZERLAND = 'CO1_SWITZERLAND',
  CO2_GERMANY = 'CO2_GERMANY',
  CO3_AUSTRIA = 'CO3_AUSTRIA',
  CO4_MALDIVES = 'CO4_MALDIVES',
}

export enum C_Created_Invoices_Filter_Mode {
  CURRENT_MONTH = 'CURRENT_MONTH',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
  CUSTOMER_LIST = 'CUSTOMER_LIST',
  DATE_RANGE = 'DATE_RANGE',
  INVOICES_NO_RANGE = 'INVOICES_NO_RANGE',
  LAST_MONTH = 'LAST_MONTH',
  ONE_TIME_CUSTOMERS = 'ONE_TIME_CUSTOMERS',
  UNPAID_INVOICES_TILL_DATE = 'UNPAID_INVOICES_TILL_DATE',
}

export enum C_Created_Orders_Filter_Type {
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
  CUSTOMER_LIST = 'CUSTOMER_LIST',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  DELIVERY_NOTES_RANGE = 'DELIVERY_NOTES_RANGE',
  ONE_TIME_CUSTOMERS = 'ONE_TIME_CUSTOMERS',
}

export enum C_Create_Order_Customer_Type {
  ACCEPTED_OFFERS = 'ACCEPTED_OFFERS',
  ACTIVE = 'ACTIVE',
  CUSTOMER_ID = 'CUSTOMER_ID',
  DAILY = 'DAILY',
  EMPTY_ORDERS = 'EMPTY_ORDERS',
  INACTIVE = 'INACTIVE',
}

export enum C_Currency {
  CUR1_DEFAULT = 'CUR1_DEFAULT',
  CUR2_EURO = 'CUR2_EURO',
  CUR3_USD = 'CUR3_USD',
}

export enum C_Customer_Address_Kind {
  CAK1_COMPANY = 'CAK1_COMPANY',
  CAK2_COMPANY_WITH_DELIVERY_ADDRESS = 'CAK2_COMPANY_WITH_DELIVERY_ADDRESS',
  CAK3_PERSONAL = 'CAK3_PERSONAL',
}

export enum C_Customer_Def_Order_Kind {
  DO1_DEFAULT_ORDER = 'DO1_DEFAULT_ORDER',
  DO2_WEEK_AGO_ORDER = 'DO2_WEEK_AGO_ORDER',
  DO3_LAST_ORDER = 'DO3_LAST_ORDER',
  DO4_EMPTY_ORDER = 'DO4_EMPTY_ORDER',
}

export enum C_Debitor_Status {
  DS1_DEBITOR = 'DS1_DEBITOR',
  DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR = 'DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR',
  DS3_INTERNAL_INVOICE = 'DS3_INTERNAL_INVOICE',
}

export enum C_Dictionary {
  DICT1_HISTORY_OPERATIONS = 'DICT1_HISTORY_OPERATIONS',
  DICT2_HISTORY_FORM_TYPES = 'DICT2_HISTORY_FORM_TYPES',
  DICT3_REPORT_GROUPS = 'DICT3_REPORT_GROUPS',
  DICT4_REPORT_TYPES = 'DICT4_REPORT_TYPES',
  DICT5_DEFAULT_FORMULAR = 'DICT5_DEFAULT_FORMULAR',
  DICT6_TASK_CODES = 'DICT6_TASK_CODES',
  DICT7_COUNTRIES = 'DICT7_COUNTRIES',
  DICT8_WEIGHT_FRACTION_TYPES = 'DICT8_WEIGHT_FRACTION_TYPES',
  DICT9_PRICE_ROUNDING_METHODS = 'DICT9_PRICE_ROUNDING_METHODS',
  DICT11_DISCOUNTS = 'DICT11_DISCOUNTS',
  DICT12_TAX_KINDS = 'DICT12_TAX_KINDS',
  DICT13_TAX_INCLUSION_KINDS = 'DICT13_TAX_INCLUSION_KINDS',
  DICT15_DIRECT_DEBIT_BANKS = 'DICT15_DIRECT_DEBIT_BANKS',
  DICT17_BOOKING_TYPES = 'DICT17_BOOKING_TYPES',
  DICT18_INVOICE_INTERVALS = 'DICT18_INVOICE_INTERVALS',
  DICT21_ORDER_TYPES = 'DICT21_ORDER_TYPES',
  DICT22_DEBITOR_STATUSES = 'DICT22_DEBITOR_STATUSES',
  DICT24_CUSTOMER_DEFAULT_ORDER_KINDS = 'DICT24_CUSTOMER_DEFAULT_ORDER_KINDS',
  DICT26_KG_AMOUNT_REPRESENTATION_KINDS = 'DICT26_KG_AMOUNT_REPRESENTATION_KINDS',
  DICT28_SALE_UNITS = 'DICT28_SALE_UNITS',
  DICT29_REDUCTIONS_AND_PRICES_TAKE_FROM_KINDS = 'DICT29_REDUCTIONS_AND_PRICES_TAKE_FROM_KINDS',
  DICT30_CA_VISIBILITY_STATUSES = 'DICT30_CA_VISIBILITY_STATUSES',
  DICT31_CUSTOMER_ADDRESS_KINDS = 'DICT31_CUSTOMER_ADDRESS_KINDS',
  DICT32_BESR_FORMATS = 'DICT32_BESR_FORMATS',
  DICT33_STATISTIC_GROUP_BY_TYPES = 'DICT33_STATISTIC_GROUP_BY_TYPES',
  DICT34_STATISTIC_PROFILES = 'DICT34_STATISTIC_PROFILES',
  DICT35_ORDER_POSITIONS_SORTING_KINDS = 'DICT35_ORDER_POSITIONS_SORTING_KINDS',
  DICT36_PRODUCTION_REPORT_SORTING_KINDS = 'DICT36_PRODUCTION_REPORT_SORTING_KINDS',
  DICT37_TASK_GROUP_TYPES = 'DICT37_TASK_GROUP_TYPES',
  DICT38_TASK_TYPES = 'DICT38_TASK_TYPES',
  DICT39_CURRENCIES = 'DICT39_CURRENCIES',
  DICT40_PRODUCTION_CUSTOMER_SORTING_KINDS = 'DICT40_PRODUCTION_CUSTOMER_SORTING_KINDS',
  DICT41_SPECIAL_PRICES_TYPES = 'DICT41_SPECIAL_PRICES_TYPES',
  DICT42_PRINTING_SERVICE_TASK_STATES = 'DICT42_PRINTING_SERVICE_TASK_STATES',
  DICT43_PRINTING_SERVICE_TASK_INITIATORS = 'DICT43_PRINTING_SERVICE_TASK_INITIATORS',
}

export enum C_Direct_Debit_Banks {
  B1_NO_BANK = 'B1_NO_BANK',
  B2_BANK1_WITH_DTA = 'B2_BANK1_WITH_DTA',
  B3_BANK2_WITH_DTA = 'B3_BANK2_WITH_DTA',
  B4_MANUAL = 'B4_MANUAL',
}

export enum C_Discount_Code {
  DC1_NO_DISCOUNT = 'DC1_NO_DISCOUNT',
  DC2_ALL_ARTICLES = 'DC2_ALL_ARTICLES',
  DC3_ARTICLE_GROUPS = 'DC3_ARTICLE_GROUPS',
  DC4_PRICE_REDUCE = 'DC4_PRICE_REDUCE',
  DC5_QUANTITY_DISCOUNT = 'DC5_QUANTITY_DISCOUNT',
  DC6_QUANTITY_DISCOUNT_GROUPS = 'DC6_QUANTITY_DISCOUNT_GROUPS',
}

export enum C_Email_Msg_State {
  EMS0_NOT_SENT = 'EMS0_NOT_SENT',
  EMS1_SENDING = 'EMS1_SENDING',
  EMS2_SENT = 'EMS2_SENT',
}

export enum C_Error_Code {
  EC1_PAGE_BREAK = 'EC1_PAGE_BREAK',
  EC2_DELIVERY_COST = 'EC2_DELIVERY_COST',
  EC3_GROUP_HEADER = 'EC3_GROUP_HEADER',
}

export enum C_Freezer_Condition {
  FC1_MINUS_18 = 'FC1_MINUS_18',
  FC2_PLUS_5 = 'FC2_PLUS_5',
}

export enum C_History_Form_Types {
  HFT1_CONFIGURATION = 'HFT1_CONFIGURATION',
  HFT2_CUSTOMER = 'HFT2_CUSTOMER',
  HFT3_ARTICLE = 'HFT3_ARTICLE',
  HFT4_PRODUCTION = 'HFT4_PRODUCTION',
  HFT5_ORDER = 'HFT5_ORDER',
  HFT6_INVOICE = 'HFT6_INVOICE',
  HFT7_TEMPORDER = 'HFT7_TEMPORDER',
  HFT8_CUSTOMER_SPECIAL_PRICES_AND_DISCOUNTS = 'HFT8_CUSTOMER_SPECIAL_PRICES_AND_DISCOUNTS',
  HFT9_OFFER = 'HFT9_OFFER',
  HFT10_GROUPTASK = 'HFT10_GROUPTASK',
  HFT11_TEMPOFFER = 'HFT11_TEMPOFFER',
  HFT12_EMPTYORDER = 'HFT12_EMPTYORDER',
}

export enum C_History_Sys_Opearations {
  HSO1_NEW_RECORD_WAS_ADDED = 'HSO1_NEW_RECORD_WAS_ADDED',
  HSO2_RECORD_WAS_EDITED = 'HSO2_RECORD_WAS_EDITED',
  HSO3_RECORD_WAS_DELETED = 'HSO3_RECORD_WAS_DELETED',
  HSO4_CHILD_RECORD_WAS_DELETED = 'HSO4_CHILD_RECORD_WAS_DELETED',
  HSO5_RECORD_WAS_COPIED = 'HSO5_RECORD_WAS_COPIED',
  HSO6_RECORD_WAS_RESTORED = 'HSO6_RECORD_WAS_RESTORED',
}

export enum C_Invoice_Deletion_Possibility {
  IDP0_POSSIBLE = 'IDP0_POSSIBLE',
  IDP1_PROHIBITED_BECAUSE_OF_NON_CASH_PAYMENT = 'IDP1_PROHIBITED_BECAUSE_OF_NON_CASH_PAYMENT',
  IDP2_PROHIBITED_BECAUSE_OF_PAY_NET_PAYMENT = 'IDP2_PROHIBITED_BECAUSE_OF_PAY_NET_PAYMENT',
}

export enum C_Invoice_Interval {
  II1_MONTHLY = 'II1_MONTHLY',
  II2_MONTHLY2 = 'II2_MONTHLY2',
  II3_MONTHLY3 = 'II3_MONTHLY3',
  II4_MONTHLY4 = 'II4_MONTHLY4',
  II5_HALF_MONTHLY = 'II5_HALF_MONTHLY',
  II6_HALF_WEEKLY = 'II6_HALF_WEEKLY',
  II7_MANUAL = 'II7_MANUAL',
  II8_DIRECT_CASH = 'II8_DIRECT_CASH',
  II9_DIRECT_DEBITOR = 'II9_DIRECT_DEBITOR',
  II10_NO_INVOICE_INTERNAL = 'II10_NO_INVOICE_INTERNAL',
}

export enum C_Kg_Representation {
  KGR1_1_IS_1 = 'KGR1_1_IS_1',
  KGR2_1000_IS_1 = 'KGR2_1000_IS_1',
}

export enum C_Language {
  L1_ENGLISH = 'L1_ENGLISH',
  L2_GERMAN = 'L2_GERMAN',
  L3_FRENCH = 'L3_FRENCH',
}

export enum C_Order_Positions_Sorting_Kind {
  OPS1_ARTICLE_NO = 'OPS1_ARTICLE_NO',
  OPS2_DESCRIPTION = 'OPS2_DESCRIPTION',
  OPS3_ORIGINAL = 'OPS3_ORIGINAL',
}

export enum C_Order_Type {
  OT1_ORDER = 'OT1_ORDER',
  OT2_ADDITIONAL_ORDER = 'OT2_ADDITIONAL_ORDER',
  OT3_PICKING_LIST = 'OT3_PICKING_LIST',
  OT4_RETURN = 'OT4_RETURN',
  OT5_CREDIT_ORDER = 'OT5_CREDIT_ORDER',
  OT6_SPECIAL_ORDER = 'OT6_SPECIAL_ORDER',
  OT7_INTERNET_ORDER = 'OT7_INTERNET_ORDER',
  OT8_HOGASHOP = 'OT8_HOGASHOP',
  OT9_CATERING = 'OT9_CATERING',
  OT11_CASHASSIST_ORDER = 'OT11_CASHASSIST_ORDER',
  OT12_CASHASSIST_REDISCOUNT = 'OT12_CASHASSIST_REDISCOUNT',
  OT13_CASHASSIST_OUTPUT = 'OT13_CASHASSIST_OUTPUT',
  OT14_CASHASSIST_RETURN = 'OT14_CASHASSIST_RETURN',
  OT15_CASHASSIST_TRANSFER = 'OT15_CASHASSIST_TRANSFER',
  OT16_CASHASSIST_ADDITIONAL_ORDER = 'OT16_CASHASSIST_ADDITIONAL_ORDER',
  OT20_CASHASSIST_CUSTOMER_ORDER = 'OT20_CASHASSIST_CUSTOMER_ORDER',
  OT21_CASHASSIST_INVOICE = 'OT21_CASHASSIST_INVOICE',
  OT22_CASHASSIST_CREDIT_CUSTOMER = 'OT22_CASHASSIST_CREDIT_CUSTOMER',
}

export enum C_Printing_Service_Task_Initiator {
  PSTI1_WAWI_AUTOMATION = 'PSTI1_WAWI_AUTOMATION',
  PSTI2_CASHASSIST_ORDERS_IMPORT = 'PSTI2_CASHASSIST_ORDERS_IMPORT',
  PSTI3_B2B_ORDERS_IMPORT = 'PSTI3_B2B_ORDERS_IMPORT',
  PSTI4_WAWI = 'PSTI4_WAWI',
}

export enum C_Printing_Service_Task_State {
  PSTS1_NOT_STARTED = 'PSTS1_NOT_STARTED',
  PSTS2_READY = 'PSTS2_READY',
  PSTS3_ERROR_BUILDING_STAGE = 'PSTS3_ERROR_BUILDING_STAGE',
  PSTS4_ERROR_PRINTING_STAGE = 'PSTS4_ERROR_PRINTING_STAGE',
  PSTS5_IN_PROGRESS = 'PSTS5_IN_PROGRESS',
  PSTS6_CANCELLED_BY_USER = 'PSTS6_CANCELLED_BY_USER',
  PSTS7_GENERATED_TOO_MANY_PAGES = 'PSTS7_GENERATED_TOO_MANY_PAGES',
}

export enum C_Production_Article_Sorting_Kind {
  PASK1_DOUGH_ARTICLE_NO = 'PASK1_DOUGH_ARTICLE_NO',
  PASK2_ARTICLE_NO = 'PASK2_ARTICLE_NO',
  PASK3_DESCRIPTION = 'PASK3_DESCRIPTION',
  PASK4_GROUP_ARTICLE_NO = 'PASK4_GROUP_ARTICLE_NO',
  PASK5_GROUP_DESCRIPTION = 'PASK5_GROUP_DESCRIPTION',
  PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO = 'PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO',
  PASK7_ARTICLE_NO_SETUP_STATION = 'PASK7_ARTICLE_NO_SETUP_STATION',
  PASK8_DELIVERY_TIME = 'PASK8_DELIVERY_TIME',
}

export enum C_Production_Customer_Sorting_Kind {
  PCSK1_CUSTOMER_NO = 'PCSK1_CUSTOMER_NO',
  PCSK2_CUSTOMER_NAME = 'PCSK2_CUSTOMER_NAME',
}

export enum C_Reductions_And_Prices_Kind {
  RNP1_DISCOUNT_WAWI_PRICE_CASH = 'RNP1_DISCOUNT_WAWI_PRICE_CASH',
  RNP2_DISCOUNT_WAWI_PRICE_WAWI = 'RNP2_DISCOUNT_WAWI_PRICE_WAWI',
  RNP3_NO_DISCOUNT_PRICE_CASH = 'RNP3_NO_DISCOUNT_PRICE_CASH',
  RNP4_DISCOUNT_CASH_PRICE_WAWI = 'RNP4_DISCOUNT_CASH_PRICE_WAWI',
  RNP5_DISCOUNT_CASH_PRICE_CASH = 'RNP5_DISCOUNT_CASH_PRICE_CASH',
  RNP6_DISCOUNT_SPECIAL_PRICE_CASH = 'RNP6_DISCOUNT_SPECIAL_PRICE_CASH',
}

export enum C_Report {
  R0_USER_REPORT = 'R0_USER_REPORT',
  R1_BAKING_LIST_2_COLUMNS = 'R1_BAKING_LIST_2_COLUMNS',
  R3_ADDRESS_LIST_A4_LANDSCAPE = 'R3_ADDRESS_LIST_A4_LANDSCAPE',
  R4_FACTSHEET = 'R4_FACTSHEET',
  R5_PHONES_LIST = 'R5_PHONES_LIST',
  R6_ARTICLE_LIST = 'R6_ARTICLE_LIST',
  R7_WEEKLY_ORDERING_LIST = 'R7_WEEKLY_ORDERING_LIST',
  R8_DELIVERY_NOTE_A4_WITH_PRICE = 'R8_DELIVERY_NOTE_A4_WITH_PRICE',
  R9_INVOICE_A4 = 'R9_INVOICE_A4',
  R10_CUSTOMERS_DEBT_NOTIFIER = 'R10_CUSTOMERS_DEBT_NOTIFIER',
  R11_CROSSTABLE_REPORT_A4_LANDSCAPE = 'R11_CROSSTABLE_REPORT_A4_LANDSCAPE',
  R12_LABEL = 'R12_LABEL',
  R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS = 'R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS',
  R15_FORWARDING_LIST_CUSTOMER_ARTICLES = 'R15_FORWARDING_LIST_CUSTOMER_ARTICLES',
  R16_DELIVERY_NOTE_A4_WITHOUT_PRICE = 'R16_DELIVERY_NOTE_A4_WITHOUT_PRICE',
  R17_INVOICE_SUMMARY = 'R17_INVOICE_SUMMARY',
  R18_INVOICE_PAYMENTSLIP = 'R18_INVOICE_PAYMENTSLIP',
  R19_INVOICE_A4_DETAILED = 'R19_INVOICE_A4_DETAILED',
  R20_FORWARDING_LIST_ARTICLE_CUSTOMERS = 'R20_FORWARDING_LIST_ARTICLE_CUSTOMERS',
  R21_INVOICE_JOURNAL = 'R21_INVOICE_JOURNAL',
  R22_BESR_IMPORT_JOURNAL_A4_LANDSCAPE = 'R22_BESR_IMPORT_JOURNAL_A4_LANDSCAPE',
  R23_DEBITOR_JOURNAL_A4_LANDSCAPE = 'R23_DEBITOR_JOURNAL_A4_LANDSCAPE',
  R24_PAYMENT_JOURNAL_A4_LANDSCAPE = 'R24_PAYMENT_JOURNAL_A4_LANDSCAPE',
  R25_BAKING_LIST_2_COLUMNS_COMBINED = 'R25_BAKING_LIST_2_COLUMNS_COMBINED',
  R26_BAKING_LIST_WITH_RECIPE_A4 = 'R26_BAKING_LIST_WITH_RECIPE_A4',
  R27_BAKING_LIST_WITH_RECIPE_A5 = 'R27_BAKING_LIST_WITH_RECIPE_A5',
  R28_WEEKLY_ORDERING_LIST_BLANK_QUANTITY = 'R28_WEEKLY_ORDERING_LIST_BLANK_QUANTITY',
  R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE = 'R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE',
  R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE = 'R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE',
  R31_INVOICE_A4_ARTICLE_GROUPING = 'R31_INVOICE_A4_ARTICLE_GROUPING',
  R32_TOURLIST = 'R32_TOURLIST',
  R33_PRICE_LIST_FOR_CUSTOMER = 'R33_PRICE_LIST_FOR_CUSTOMER',
  R34_BAKING_LIST_A4 = 'R34_BAKING_LIST_A4',
  R35_EXTENDED_PAYMENT_JOURNAL = 'R35_EXTENDED_PAYMENT_JOURNAL',
  R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES = 'R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES',
  R37_OFFER_A4 = 'R37_OFFER_A4',
  R38_OFFER_A4_ARTICLE_GROUPING = 'R38_OFFER_A4_ARTICLE_GROUPING',
  R39_INVOICE_A4_CUSTOMER_GROUPING = 'R39_INVOICE_A4_CUSTOMER_GROUPING',
  R40_DELIVERY_NOTE_A4_OVERVIEW = 'R40_DELIVERY_NOTE_A4_OVERVIEW',
  R41_BAKING_LIST_A4_DETAILED = 'R41_BAKING_LIST_A4_DETAILED',
  R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE = 'R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE',
  R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT = 'R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT',
  R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT = 'R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT',
  R45_SHIPPING_LABEL_ADDRESS_ONLY = 'R45_SHIPPING_LABEL_ADDRESS_ONLY',
  R46_SHIPPING_LABEL_FOR_ORDERED_ARTICLE = 'R46_SHIPPING_LABEL_FOR_ORDERED_ARTICLE',
  R47_WEEKLY_ORDERINGLIST_BLANK_QUANTITY_BLANK_DATE = 'R47_WEEKLY_ORDERINGLIST_BLANK_QUANTITY_BLANK_DATE',
  R48_OFFER_A4_WITH_IMAGES = 'R48_OFFER_A4_WITH_IMAGES',
  R49_BAKING_LIST_2_COLUMNS_SMART_SCALE = 'R49_BAKING_LIST_2_COLUMNS_SMART_SCALE',
  R50_ARTICLE_LIST_WITH_IMAGES = 'R50_ARTICLE_LIST_WITH_IMAGES',
  R51_DELIVERY_NOTES_IN_PRODUCTION = 'R51_DELIVERY_NOTES_IN_PRODUCTION',
  R52_DELIVERY_OVERVIEW = 'R52_DELIVERY_OVERVIEW',
  R53_PROCUREMENT_ORDER_A4 = 'R53_PROCUREMENT_ORDER_A4',
  R54_PROCUREMENT_PROJECT_A4 = 'R54_PROCUREMENT_PROJECT_A4',
  R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN = 'R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN',
  R56_BAKING_LIST_VERSIONS_DIFFERENCE = 'R56_BAKING_LIST_VERSIONS_DIFFERENCE',
  R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS = 'R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS',
  R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE = 'R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE',
  R59_INVOICE_PAYMENTSLIP_WITH_QR = 'R59_INVOICE_PAYMENTSLIP_WITH_QR',
  R60_STOCK_LIST = 'R60_STOCK_LIST',
  R61_STOCK_VALUE = 'R61_STOCK_VALUE',
  R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE = 'R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE',
  R63_PROCUREMENT_SUPPLIER_GOODS_A4 = 'R63_PROCUREMENT_SUPPLIER_GOODS_A4',
  R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP = 'R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP',
  R65_CROSSTABLE_REPORT_A4_PORTRAIT = 'R65_CROSSTABLE_REPORT_A4_PORTRAIT',
  R66_BAKING_LIST_FOR_CAKES_AND_PIES = 'R66_BAKING_LIST_FOR_CAKES_AND_PIES',
}

export enum C_Report_Group {
  RG1_PRODUCTION_PRODUCTION = 'RG1_PRODUCTION_PRODUCTION',
  RG2_CUSTOMER = 'RG2_CUSTOMER',
  RG3_ARTICLE = 'RG3_ARTICLE',
  RG4_DEFAULT_ORDER = 'RG4_DEFAULT_ORDER',
  RG5_ORDER = 'RG5_ORDER',
  RG6_INVOICE = 'RG6_INVOICE',
  RG7_DEBITOR = 'RG7_DEBITOR',
  RG8_PRODUCTION_FORWARDING_LISTS = 'RG8_PRODUCTION_FORWARDING_LISTS',
  RG9_PRODUCTION_CROSSTABLE = 'RG9_PRODUCTION_CROSSTABLE',
  RG10_OFFER = 'RG10_OFFER',
  RG11_PRODUCTION_SMARTSCALE = 'RG11_PRODUCTION_SMARTSCALE',
  RG12_PROCUREMENT = 'RG12_PROCUREMENT',
  RG13_PROCUREMENT_GOODS = 'RG13_PROCUREMENT_GOODS',
  RG14_PROCUREMENT_SUPPLIERS = 'RG14_PROCUREMENT_SUPPLIERS',
}

export enum C_Rounding_Method {
  RM1_NOT_USED = 'RM1_NOT_USED',
  RM2_TO_0_05 = 'RM2_TO_0_05',
  RM3_TO_0_01 = 'RM3_TO_0_01',
  RM4_TO_0_001 = 'RM4_TO_0_001',
  RM5_TO_0_1 = 'RM5_TO_0_1',
}

export enum C_Sale_Unit {
  SU1_PIECE = 'SU1_PIECE',
  SU2_100G = 'SU2_100G',
  SU3_1000G = 'SU3_1000G',
  SU4_PIECE_AND_WEIGTH = 'SU4_PIECE_AND_WEIGTH',
  SU5_OPEN_AMOUNT = 'SU5_OPEN_AMOUNT',
  SU6_OPEN_AMOUNT_MINUS = 'SU6_OPEN_AMOUNT_MINUS',
}

export enum C_Special_Price_Type {
  SP1_PRICE = 'SP1_PRICE',
  SP2_DISCOUNT = 'SP2_DISCOUNT',
  SP3_PRICE_AND_DISCOUNT = 'SP3_PRICE_AND_DISCOUNT',
  SP4_PRICE_IF_QUANTITY_MORE = 'SP4_PRICE_IF_QUANTITY_MORE',
  SP5_DISCOUNT_IF_QUANTITY_MORE = 'SP5_DISCOUNT_IF_QUANTITY_MORE',
  SP6_PRICE_AND_DISCOUNT_IF_QUANTITY_MORE = 'SP6_PRICE_AND_DISCOUNT_IF_QUANTITY_MORE',
}

export enum C_Statistic_Group_By_Type {
  SGRP1_ARTICLE = 'SGRP1_ARTICLE',
  SGRP2_CUSTOMER_ARTICLE = 'SGRP2_CUSTOMER_ARTICLE',
  SGRP3_ARTICLE_CUSTOMER = 'SGRP3_ARTICLE_CUSTOMER',
  SGRP4_DATE_ARTICLE = 'SGRP4_DATE_ARTICLE',
  SGRP5_ARTICLE_DATE = 'SGRP5_ARTICLE_DATE',
  SGRP6_ARTICLE_DATE_CUSTOMER = 'SGRP6_ARTICLE_DATE_CUSTOMER',
  SGRP7_ARTICLE_DATE_RANGE_DETAIL = 'SGRP7_ARTICLE_DATE_RANGE_DETAIL',
  SGRP8_ARTICLE_DATE_RANGE_DETAIL_TURNOVER = 'SGRP8_ARTICLE_DATE_RANGE_DETAIL_TURNOVER',
}

export enum C_Task_Group_Type {
  TG1_ACTIONS_AUTOMATION = 'TG1_ACTIONS_AUTOMATION',
  TG2_REPORTS_AUTOMATION = 'TG2_REPORTS_AUTOMATION',
}

export enum C_Task_Type {
  TT1_HOGASHOP_EXPORT_ARTICLES = 'TT1_HOGASHOP_EXPORT_ARTICLES',
  TT2_HOGASHOP_EXPORT_PRICES = 'TT2_HOGASHOP_EXPORT_PRICES',
  TT3_HOGASHOP_IMPORT_ORDERS = 'TT3_HOGASHOP_IMPORT_ORDERS',
  TT4_REIMPORT_DEFAULT_ORDERS = 'TT4_REIMPORT_DEFAULT_ORDERS',
  TT5_PRINT_PRODUCTION_REPORT = 'TT5_PRINT_PRODUCTION_REPORT',
  TT6_EXPORT_FOR_WEBSHOP = 'TT6_EXPORT_FOR_WEBSHOP',
  TT7_TOOLBOX_IMPORT = 'TT7_TOOLBOX_IMPORT',
  TT8_TOOLBOX_EXPORT = 'TT8_TOOLBOX_EXPORT',
  TT9_CUSTOMERS_IMPORT = 'TT9_CUSTOMERS_IMPORT',
}

export enum C_Tax_Inclusion_Type {
  TAX1_INCLUDED_VAT = 'TAX1_INCLUDED_VAT',
  TAX2_EXCLUDED_VAT = 'TAX2_EXCLUDED_VAT',
  TAX3_NO_VAT = 'TAX3_NO_VAT',
  TAX4_INDIVIDUAL_FOR_CLIENT = 'TAX4_INDIVIDUAL_FOR_CLIENT',
}

export enum C_Vat_Code {
  VT1_NORMAL = 'VT1_NORMAL',
  VT2_REDUCED = 'VT2_REDUCED',
  VT3_SPECIAL1 = 'VT3_SPECIAL1',
  VT4_SPECIAL2 = 'VT4_SPECIAL2',
  VT5_ZERO = 'VT5_ZERO',
}

export enum C_Virtual_Position {
  VP1_PAGE_BREAK = 'VP1_PAGE_BREAK',
  VP2_DELIVERY_COST = 'VP2_DELIVERY_COST',
  VP3_GROUP_HEADER = 'VP3_GROUP_HEADER',
}

export enum C_Weigth_Fraction_Type {
  WFT0_G_100G = 'WFT0_G_100G',
  WFT1_MG_100G = 'WFT1_MG_100G',
  WFT2_G_1OZ = 'WFT2_G_1OZ',
  WFT3_G_1LB = 'WFT3_G_1LB',
  WFT4_KCAL_100G = 'WFT4_KCAL_100G',
  WFT5_KJ_100G = 'WFT5_KJ_100G',
  WFT6_CAL_100G = 'WFT6_CAL_100G',
  WFT7_MG_1OZ = 'WFT7_MG_1OZ',
  WFT8_MUG_100G = 'WFT8_MUG_100G',
  WFT9_NG_100G = 'WFT9_NG_100G',
  WFT10_KCAL_1OZ = 'WFT10_KCAL_1OZ',
  WFT11_KJ_1OZ = 'WFT11_KJ_1OZ',
  WFT12_CAL_1OZ = 'WFT12_CAL_1OZ',
  WFT13_KCAL_1LB = 'WFT13_KCAL_1LB',
  WFT14_KJ_1LB = 'WFT14_KJ_1LB',
  WFT15_CAL_1LB = 'WFT15_CAL_1LB',
}

export type CreateOrderInput = {
  customerId: Scalars['ID'];
  date: Scalars['String'];
  positions: Array<CreateOrderPositionInput>;
};

export type CreateOrderPositionInput = {
  linkArticle: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type CursorsParams = {
  /** Number of records to include after cursor */
  after?: InputMaybe<Scalars['ID']>;
  /** Number of records to include before cursor */
  before?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type CustomerFieldsInput = {
  amount?: InputMaybe<Scalars['Float']>;
  customerId: Scalars['Int'];
  distributorId: AvailableDistributorId;
  sku: Scalars['String'];
};

export type CustomersPrioritiesInput = {
  priority: Scalars['String'];
  sku: Scalars['String'];
};

export type DateTimeIntervalInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteProductsInput = {
  customerNum: Scalars['String'];
  dateFor: Scalars['String'];
  orderId: Scalars['Int'];
  partNum?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  productName: Scalars['String'];
  productStatus?: InputMaybe<AvailableStatus>;
  responsibleId: AvailableDistributorId;
};

export type DeviceItemFieldsInput = {
  customers?: InputMaybe<Array<InputMaybe<CustomerFieldsInput>>>;
  date: Scalars['String'];
  productName: Scalars['String'];
  productStatus: AvailableStatus;
  shippingName: Scalars['String'];
};

export type FullDateIntervalInput = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type FullDateTimeIntervalInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type ListQueryArgs = {
  /** Parameters for cursor pagination */
  cursors?: InputMaybe<CursorsParams>;
  /** Force update ignoring cached mod_ver */
  forceUpdate?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  mod_ver?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<SearchParams>;
  sort?: InputMaybe<Array<SortParams>>;
};

export enum NotificationMessage {
  NEW_VERSION_IS_DEPLOYED = 'NEW_VERSION_IS_DEPLOYED',
  PRINTER_IS_OFFLINE = 'PRINTER_IS_OFFLINE',
  PRINTER_IS_ONLINE = 'PRINTER_IS_ONLINE',
}

export type OrderInfo = {
  orderId: Scalars['ID'];
  positionParts: Array<OrderPositionPartInfo>;
};

export type OrderPositionPartInfo = {
  description: Scalars['String'];
  linkArticle: Scalars['ID'];
  linkReplacementArticle?: InputMaybe<Scalars['ID']>;
  newQuantity?: InputMaybe<Scalars['Float']>;
  partNum?: InputMaybe<Scalars['Float']>;
};

export enum PageNamesEnum {
  DISTRIBUTION = 'DISTRIBUTION',
  PRODUCTION = 'PRODUCTION',
}

export type PrintData = {
  productName: Scalars['String'];
  productNr: Scalars['String'];
  shops: Array<Shop>;
};

export type PrinterSettingsInput = {
  isLocalConnection?: InputMaybe<Scalars['Boolean']>;
  localIP?: InputMaybe<Scalars['String']>;
  printerId: Scalars['String'];
  shippingPoint: Scalars['String'];
};

export type ProductUpdateFields = {
  distributed?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  orderId: Scalars['Int'];
  partNum?: InputMaybe<Scalars['Int']>;
  productStatus: AvailableStatus;
  responsibleId?: InputMaybe<Scalars['Int']>;
  toDistribute?: InputMaybe<Scalars['Float']>;
};

export type RangeIds = {
  from: Scalars['ID'];
  to: Scalars['ID'];
};

export type Smp_FilterDate = {
  date?: InputMaybe<Scalars['String']>;
  secondDate?: InputMaybe<Scalars['String']>;
};

export type Smp_GeneralFilter = {
  column?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SearchParams = {
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  string?: InputMaybe<Scalars['String']>;
};

export type Shop = {
  count: Scalars['Int'];
  shopName: Scalars['String'];
  shopNr: Scalars['String'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortParams = {
  field: Scalars['String'];
  order?: InputMaybe<SortOrder>;
};

export type TimeIntervalInput = {
  from?: InputMaybe<Scalars['Time']>;
  to?: InputMaybe<Scalars['Time']>;
};

export type UnbindDeviceInput = {
  serialNumber: Scalars['String'];
};

export type WeekDaysAvailabilityInput = {
  friday?: InputMaybe<Scalars['Boolean']>;
  monday?: InputMaybe<Scalars['Boolean']>;
  saturday?: InputMaybe<Scalars['Boolean']>;
  sunday?: InputMaybe<Scalars['Boolean']>;
  thursday?: InputMaybe<Scalars['Boolean']>;
  tuesday?: InputMaybe<Scalars['Boolean']>;
  wednesday?: InputMaybe<Scalars['Boolean']>;
};

export enum AuthRoles {
  ADMIN = 'ADMIN',
  CASH_ASSIST = 'CASH_ASSIST',
  CASH_ASSIST_ADMIN = 'CASH_ASSIST_ADMIN',
  CONSOLE = 'CONSOLE',
  CONSOLE_ADMIN = 'CONSOLE_ADMIN',
  FAKTURA_ASSIST = 'FAKTURA_ASSIST',
  FAKTURA_ASSIST_ADMIN = 'FAKTURA_ASSIST_ADMIN',
  REZEPT_ASSIST = 'REZEPT_ASSIST',
  REZEPT_ASSIST_ADMIN = 'REZEPT_ASSIST_ADMIN',
  ROOT = 'ROOT',
  SMART_PICKING = 'SMART_PICKING',
}

export enum ClientType {
  CUSTOMERAPI_SERVICE = 'CUSTOMERAPI_SERVICE',
  KIOSK_UTILS = 'KIOSK_UTILS',
  MOBILE_WAITER = 'MOBILE_WAITER',
  SELF_CHECKOUT = 'SELF_CHECKOUT',
  SMART_CHECKOUT = 'SMART_CHECKOUT',
  SMART_LABEL = 'SMART_LABEL',
  SMART_SCALE = 'SMART_SCALE',
  WEB_APP = 'WEB_APP',
}

export enum CustomRights {
  CAW_CUSTOMERS_CREDIT = 'CAW_CUSTOMERS_CREDIT',
  CAW_DASHBOARD = 'CAW_DASHBOARD',
  CAW_DEBIT_CARD = 'CAW_DEBIT_CARD',
  CAW_DEGREES = 'CAW_DEGREES',
  CAW_EMPLOYEE = 'CAW_EMPLOYEE',
  CAW_MEDIA_CENTER = 'CAW_MEDIA_CENTER',
  CAW_MESSAGES = 'CAW_MESSAGES',
  CAW_OPTIONS = 'CAW_OPTIONS',
  CAW_PRODUCT = 'CAW_PRODUCT',
  CAW_STATISTICS = 'CAW_STATISTICS',
  CAW_TEMPLATE = 'CAW_TEMPLATE',
}

export enum RichTextType {
  html = 'html',
  plain = 'plain',
  rtf = 'rtf',
}

export type GetProductionListQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetProductionListQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getProductionList?: Array<{
      __typename?: 'ProductionList';
      deliveryCycleNum?: number | null;
      articleId?: string | null;
      articleNum?: string | null;
      description?: string | null;
      isIndividualDescription?: boolean | null;
      sumAmount?: number | null;
      shippingPointId?: string | null;
      shippingPointNum?: string | null;
      shippingPointName?: string | null;
      imageUrl?: string | null;
      productionListOrders?: Array<{
        __typename?: 'ProductionListOrders';
        amount?: number | null;
        orderId?: string | null;
        deliveryNoteNum?: number | null;
        customerId?: string | null;
        customerNum?: string | null;
        internalName?: string | null;
        distributionLowerLimitInPercent?: number | null;
        distributionHigherLimitInPercent?: number | null;
        productStatus?: AvailableStatus | null;
        responsibleId?: number | null;
        distributed?: number | null;
        toDistribute?: number | null;
        priority?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetDistributionListQueryVariables = Exact<{
  date: Scalars['String'];
}>;

export type GetDistributionListQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getDistributionList?: Array<{
      __typename?: 'DistributionList';
      deliveryCycleNum?: number | null;
      customerId?: string | null;
      customerNum?: string | null;
      internalName?: string | null;
      deliveryTime?: string | null;
      deliveryAddress?: string | null;
      rotePlan?: string | null;
      orderId?: string | null;
      deliveryNoteNum?: number | null;
      products?: Array<{
        __typename?: 'DistributionProductsList';
        articleId?: string | null;
        articleNum?: string | null;
        description?: string | null;
        isIndividualDescription?: boolean | null;
        sumAmount?: number | null;
        numberPerBakingSheet?: number | null;
        shippingPointId?: string | null;
        shippingPointNum?: string | null;
        shippingPointName?: string | null;
        toDistribute?: number | null;
        distributed?: number | null;
        productStatus?: AvailableStatus | null;
        imageUrl?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type GetArticlesQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getArticles?: Array<{
      __typename?: 'Articles';
      id?: number | null;
      articleNo?: string | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateProductMutationVariables = Exact<{
  date: Scalars['String'];
  productId: Scalars['Int'];
  productName: Scalars['String'];
  // pageName: PageNamesEnum;
  shippingName: Scalars['String'];
  fields: Array<InputMaybe<ProductUpdateFields>> | InputMaybe<ProductUpdateFields>;
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; updateProduct?: boolean | null } | null;
};

export type DeleteProductItemsMutationVariables = Exact<{
  params: Array<DeleteProductsInput> | DeleteProductsInput;
}>;

export type DeleteProductItemsMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; deleteProductItems?: boolean | null } | null;
};

export type UpdateDeviceQueryVariables = Exact<{
  fields: DeviceItemFieldsInput;
}>;

export type UpdateDeviceQuery = {
  __typename?: 'Query';
  smartpicking?: { __typename?: 'SMP_Query'; updateDevice?: boolean | null } | null;
};

export type GetDevicesInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetDevicesInfoQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getDevicesInfo?: Array<{
      __typename?: 'DevicesInfo';
      sku?: string | null;
      priority?: string | null;
      serialNumber?: string | null;
      shippingPoint?: string | null;
    } | null> | null;
  } | null;
};

export type AllCustomersQueryVariables = Exact<{ [key: string]: never }>;

export type AllCustomersQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    allCustomers?: Array<{
      __typename?: 'AllCustomers';
      internalOrFullName?: string | null;
      customerId?: number | null;
      customerNo?: string | null;
    } | null> | null;
  } | null;
};

export type GetDeviceListQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeviceListQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getDeviceList?: Array<{
      __typename?: 'HanshowDeviceList';
      physicalId?: string | null;
    } | null> | null;
  } | null;
};

export type GetAllPrintersByGuidQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPrintersByGuidQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getAllPrintersByGuid?: Array<{
      __typename?: 'Printer';
      printerId?: string | null;
      localIP?: string | null;
      isLocalConnection?: boolean | null;
      shippingPoint?: string | null;
      isOnline?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetPrinterByShippingPointQueryVariables = Exact<{
  shippingPoint: Scalars['String'];
}>;

export type GetPrinterByShippingPointQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getPrinterByShippingPoint?: {
      __typename?: 'Printer';
      printerId?: string | null;
      localIP?: string | null;
      isLocalConnection?: boolean | null;
      shippingPoint?: string | null;
      isOnline?: boolean | null;
    } | null;
  } | null;
};

export type SavePrinterSettingsMutationVariables = Exact<{
  params: PrinterSettingsInput;
}>;

export type SavePrinterSettingsMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; savePrinterSettings?: boolean | null } | null;
};

export type BindDeviceMutationVariables = Exact<{
  params?: InputMaybe<Array<InputMaybe<BindDeviceInput>> | InputMaybe<BindDeviceInput>>;
}>;

export type BindDeviceMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; bindDevice?: boolean | null } | null;
};

export type UnbindDeviceMutationVariables = Exact<{
  params: Array<UnbindDeviceInput> | UnbindDeviceInput;
}>;

export type UnbindDeviceMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; unbindDevice?: boolean | null } | null;
};

export type ClearDisplaysDataMutationVariables = Exact<{
  customersSku: Array<Scalars['String']> | Scalars['String'];
}>;

export type ClearDisplaysDataMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; clearDisplaysData?: boolean | null } | null;
};

export type UpdateCustomersPrioritiesMutationVariables = Exact<{
  params: Array<CustomersPrioritiesInput> | CustomersPrioritiesInput;
}>;

export type UpdateCustomersPrioritiesMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; updateCustomersPriorities?: boolean | null } | null;
};

export type EditOrdersMutationVariables = Exact<{
  orders: Array<OrderInfo> | OrderInfo;
}>;

export type EditOrdersMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; editOrders?: boolean | null } | null;
};

export type SettingDeviceStatusMutationVariables = Exact<{
  date: Scalars['String'];
  shippingName: Scalars['String'];
  customersSku?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type SettingDeviceStatusMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; settingDeviceStatus?: boolean | null } | null;
};

export type CreateOrderMutationVariables = Exact<{
  params: CreateOrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; createOrder?: boolean | null } | null;
};

export type PrintOutForCustomersMutationVariables = Exact<{
  printerId: Scalars['String'];
  printData: PrintData;
}>;

export type PrintOutForCustomersMutation = {
  __typename?: 'Mutation';
  smartpicking?: {
    __typename?: 'SMP_Mutation';
    printOutForCustomers?: { __typename?: 'PrinterInfo'; isPrinterOnline?: boolean | null } | null;
  } | null;
};

export type ShippingPointsListQueryVariables = Exact<{ [key: string]: never }>;

export type ShippingPointsListQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    shippingPointsList?: Array<{
      __typename?: 'ShippingPoint';
      shippingId?: number | null;
      shippingNum?: string | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type GetTranslationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTranslationsQuery = {
  __typename?: 'Query';
  smartpicking?: { __typename?: 'SMP_Query'; getTranslations?: any | null } | null;
};

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetNotificationsQuery = {
  __typename?: 'Query';
  smartpicking?: {
    __typename?: 'SMP_Query';
    getNotifications?: Array<{
      __typename?: 'Notification';
      notificationId: string;
      message?: NotificationMessage | null;
      isRead?: boolean | null;
      timestamp?: number | null;
    } | null> | null;
  } | null;
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type MarkNotificationAsReadMutation = {
  __typename?: 'Mutation';
  smartpicking?: { __typename?: 'SMP_Mutation'; markNotificationAsRead?: boolean | null } | null;
};
