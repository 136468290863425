import { gqlClient } from 'shared/gql/gqlClient';

import {
  CreateOrderMutationVariables,
  DeviceItemFieldsInput,
  EditOrdersMutationVariables,
  UpdateCustomersPrioritiesMutationVariables,
} from 'graphql/generatedModel';
import {
  clearDisplay,
  createOrder as createOrderReq,
  editOrders as editOrdersReq,
  updateCustomersPriorities,
  updateDevice,
} from 'shared/gql/common.gql';

export const replaceProduct = async (orders: EditOrdersMutationVariables) => {
  const res = await gqlClient(editOrdersReq, orders);
  return res.smartpicking.editOrders;
};

export const editOrders = async (orders: EditOrdersMutationVariables) => {
  const res = await gqlClient(editOrdersReq, orders);
  return res.smartpicking.editOrders;
};

export const updateDisplay = async (fields: DeviceItemFieldsInput) => {
  const res = await gqlClient(updateDevice, { fields });
  return res.smartpicking.updateDevice;
};

export const clearDisplays = async (customersSku: string[]) => {
  const res = await gqlClient(clearDisplay, { customersSku });
  return res.smartpicking.clearDisplaysData;
};

export const updatePriorities = async (params: UpdateCustomersPrioritiesMutationVariables) => {
  const res = await gqlClient(updateCustomersPriorities, params);
  return res.smartpicking.updateCustomersPriorities;
};

export const createOrder = async (params: CreateOrderMutationVariables) => {
  const res = await gqlClient(createOrderReq, params);
  return res.smartpicking.createOrder;
};
